import { useMemo, useRef } from 'react';
import { useMutation, useQuery } from 'react-query';
import useModal from '../../shared/hooks/useModal';
import {
	GET_OPPORTUNITIES_FILTERS,
	CREATE_MARKDOWN_TRANSFER,
} from '../../shared/api/opportunities';
import useToast from '../../shared/data/toast';
import MarkdownTransferModal from '../components/MarkdownTransferModal';

interface Result {
	isPossible: boolean;
	transferInProgress: boolean;
	openModal: (...args: any[]) => any;
	closeModal: () => any;
}

interface ToastsState {
	loading?: string;
	success?: string;
	error?: string;
}

const useMarkdownTransferModal = (cumulioDashboardRef: any): Result => {
	const filtersQuery = useQuery({
		queryKey: 'checkMarkdownTransferPossible',
		queryFn: GET_OPPORTUNITIES_FILTERS,
	});
	const { open: openModal, close: closeModal } = useModal();
	const {
		show: showToast,
		close: closeToast,
		cleanup: cleanupToasts,
	} = useToast();

	const toastsRef = useRef<ToastsState>({});

	const { mutateAsync: transferFunction, isLoading: transferInProgress } =
		useMutation({
			mutationFn: CREATE_MARKDOWN_TRANSFER,
			onMutate: () => {
				cleanupToasts();
				const toastId = showToast('Markdown transfer in progress...', {
					type: 'loading',
					lifeSpan: -1,
				});
				toastsRef.current.loading = toastId;
				closeModal();
			},
			onSuccess: () => {
				closeToast(toastsRef.current.loading);
				const toastId = showToast(
					'Markdown transfer completed! Please refresh page',
					{ type: 'success', lifeSpan: -1 }
				);
				toastsRef.current.success = toastId;
			},
			onError: () => {
				closeToast(toastsRef.current.loading);
				// Generic default toast will show at this point
			},
		});

	const isPossible = useMemo(() => {
		if (filtersQuery.isSuccess && Array.isArray(filtersQuery?.data)) {
			const toFind = ['markdown_type', 'overwrite_markdown'];
			(filtersQuery.data ?? []).forEach((filter: any) => {
				const id = toFind.findIndex((filterId) => filterId === filter.id);
				if (id !== -1) {
					delete toFind[id];
				}
			});
			return true;
		}
		return true;
	}, [filtersQuery.isSuccess]);

	return {
		isPossible,
		transferInProgress,
		openModal:
			isPossible && cumulioDashboardRef?.current
				? () =>
						openModal(
							<MarkdownTransferModal
								transferMarkdownFn={transferFunction}
								cumulioRef={cumulioDashboardRef}
							/>
						)
				: () => {},
		closeModal,
	};
};

export default useMarkdownTransferModal;
