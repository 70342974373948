import { Redirect, Route } from 'react-router-dom';

import { useState } from 'react';
import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Page from '../../shared/components/Page/Page';
import OpportunitiesHeader from '../components/OpportunitiesHeader';
import OpportunitiesSummary from './OpportunitiesSummary';
import OpportunitiesDetails from './OpportunitiesDetails';

const OpportunitiesView = () => {
	const [filters, setFilters] = useState(new Map());
	return (
		<Page>
			<OpportunitiesHeader filters={filters} setFilters={setFilters} />
			<RouterSwitch>
				<Route exact path="/opportunities">
					<Redirect to="/opportunities/summary" />
				</Route>
				<Route
					exact
					path="/opportunities/summary"
					component={() => OpportunitiesSummary({ filters })}
				/>
				<Route
					exact
					path="/opportunities/details"
					component={() => OpportunitiesDetails({ filters })}
				/>
			</RouterSwitch>
		</Page>
	);
};

export default OpportunitiesView;
