import { Redirect, Route } from 'react-router-dom';

import { useState } from 'react';
import RouterSwitch from '../../shared/components/RouterSwitch/RouterSwitch';
import Page from '../../shared/components/Page/Page';
import ActualsHeader from '../components/ActualsHeader';
import ActualsInventory from './ActualsInventory';
import ActualsSales from './ActualsSales';
import ActualsYoYComparison from './ActualsYoYComparison';
import ActualsProduct from './ActualsProduct';

const ActualsView = () => {
	const [filters, setFilters] = useState(new Map());
	return (
		<Page>
			<ActualsHeader filters={filters} setFilters={setFilters} />
			<RouterSwitch>
				<Route exact path="/actuals">
					<Redirect to="/actuals/inventory" />
				</Route>
				<Route
					exact
					path="/actuals/inventory"
					component={() => ActualsInventory({ filters })}
				/>
				<Route
					exact
					path="/actuals/sales"
					component={() => ActualsSales({ filters })}
				/>
				<Route
					exact
					path="/actuals/yoycomparison"
					component={() => ActualsYoYComparison({ filters })}
				/>
				<Route
					exact
					path="/actuals/product"
					component={() => ActualsProduct({ filters })}
				/>
			</RouterSwitch>
		</Page>
	);
};

export default ActualsView;
