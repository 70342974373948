import api from './api';

export const GET_CUMULIO_RETROSPECTIVE_SSO = () => {
	return api.get(`api/v2/reports/cumulio/retrospective/sso`).json();
};

export const GET_CUMULIO_SCENARIO_SSO = () => {
	return api.get('api/v2/reports/cumulio/scenario/sso').json();
};

export const GET_CUMULIO_SCENARIO_COMPARISON_SSO = () => {
	return api.get('api/v2/reports/cumulio/scenario/comparison/sso').json();
};

export const GET_CUMULIO_OPPORTUNITIES_OVERVIEW_SSO = (
	activeStrategy,
	searchParams
) => {
	return api
		.get(
			`api/v2/reports/cumulio/opportunities/${activeStrategy}/overview/sso`,
			{ searchParams }
		)
		.json();
};

export const GET_CUMULIO_OPPORTUNITIES_GLOBAL_RETROSPECTIVE_SSO = (week) => {
	if (!week) {
		return null;
	}

	return api
		.get(`api/v2/reports/cumulio/opportunities/global/retrospective/sso`)
		.json();
};

export const GET_CUMULIO_OPPORTUNITIES_GLOBAL_SUMMARY_SSO = () => {
	return api
		.get('api/v2/reports/cumulio/opportunities/global/overview/sso')
		.json();
};
export const GET_CUMULIO_OPPORTUNITIES_PRODUCT_SSO = (
	activeStrategy,
	productId
) => {
	return api
		.get(
			`api/v2/reports/cumulio/opportunities/${activeStrategy}/${productId}/sso`
		)
		.json();
};

export const GET_CUMULIO_ACTUALS_SALES_SSO = (searchParams) => {
	return api
		.get(`api/v2/reports/cumulio/actuals/sales/sso`, { searchParams })
		.json();
};

export const GET_CUMULIO_ACTUALS_INVENTORY_SSO = (searchParams) => {
	return api
		.get(`api/v2/reports/cumulio/actuals/inventory/sso`, { searchParams })
		.json();
};

export const GET_CUMULIO_SUPPORT_VALIDATION_SSO = () => {
	return api.get(`api/v2/reports/cumulio/support/validation/sso`).json();
};

export const GET_CUMULIO_ACTUALS_YOYCOMPARISON_SSO = (searchParams) => {
	return api
		.get(`api/v2/reports/cumulio/actuals/yoycomparison/sso`, { searchParams })
		.json();
};

export const GET_CUMULIO_ACTUALS_PRODUCT_SSO = (searchParams) => {
	return api
		.get(`api/v2/reports/cumulio/actuals/product/sso`, { searchParams })
		.json();
};

export const GET_CUMULIO_GLOBAL_SSO = () => {
	return api.get(`api/v2/reports/cumulio/global/sso`).json();
};

export const GET_ACTUALS_FILTERS = () => {
	return api.get(`api/v2/reports/cumulio/actuals/filters`).json();
};
