import { GET_CUMULIO_ACTUALS_SALES_SSO } from '../../shared/api/reports';
import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';
import filtersToSearchParams from '../../shared/utils/filters';

const ActualsSales = ({ filters }) => {
	return (
		<FullCumulioDashboard
			queryKey={['cumulio-actuals-sales', Object.fromEntries(filters)]}
			ssoQuery={() =>
				GET_CUMULIO_ACTUALS_SALES_SSO(filtersToSearchParams(filters))
			}
		/>
	);
};

export default ActualsSales;
