import PropTypes from 'prop-types';
import useChannelQuery from '../../shared/hooks/useChannelQuery';
import useStrategyStore from '../../shared/data/strategies';
import FullFilterMenu from '../../shared/components/Filter/FullFilterMenu';
import { GET_ACTUALS_FILTERS } from '../../shared/api/reports';

const ActualsFilter = ({ filters, onChange }) => {
	const { activeStrategy } = useStrategyStore();
	const filterQuery = useChannelQuery(
		['actuals-filters'],
		() => GET_ACTUALS_FILTERS(activeStrategy),
		{
			staleTime: 5 * 60 * 1000,
		}
	);
	return (
		<FullFilterMenu
			filterQuery={filterQuery}
			filters={filters}
			onChange={onChange}
		/>
	);
};

ActualsFilter.propTypes = {
	filters: PropTypes.instanceOf(Map),
	onChange: PropTypes.func,
};

ActualsFilter.defaultProps = {
	filters: new Map(),
	onChange: () => {},
};

export default ActualsFilter;
