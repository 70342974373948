import { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import CircularProgress from '../Progress/CircularProgress';

import CrossIcon from '../Icons/Cross';
import Text from '../Text/Text';

const FilterFieldMenu = ({ options, loading, visible, onChange, onReset }) => {
	if (!loading && !options.length) return null;

	const othersName = 'Others';

	const optionsWithOthers = options.map((option) => {
		const newOption = { ...option };
		if (!option.label) {
			newOption.label = othersName;
		}
		return newOption;
	});

	const labels = [...new Set(optionsWithOthers.map((item) => item.label))];

	return (
		<Transition
			appear
			show={visible}
			as={Fragment}
			enter="transition ease-out duration-100"
			enterFrom="transform opacity-0 scale-95"
			enterTo="transform opacity-100 scale-100"
			leave="transition ease-in duration-75"
			leaveFrom="transform opacity-100 scale-100"
			leaveTo="transform opacity-0 scale-95"
		>
			<div
				id="filter-field-menu"
				className="origin-top-right absolute left-full -ml-8 w-64 mt-2 py-1 bg-white rounded-lg shadow-ca focus:outline-none z-20"
			>
				{loading && (
					<div className="flex justify-center py-4">
						<CircularProgress />
					</div>
				)}
				{!loading && (
					<>
						{labels.map((label) => (
							<div key={label} className="mb-1">
								{labels.length > 1 && (
									<div className="px-4 py-2 transition-colors decoration-solid font-bold border-b-2">
										<Text>{label}</Text>
									</div>
								)}
								{optionsWithOthers
									.filter((o) => o.label === label)
									.map((o) => (
										<div
											role="button"
											tabIndex="0"
											key={o.id}
											className="px-4 py-2 cursor-pointer transition-colors hover:bg-ca-silver"
											onClick={() => onChange(o.id)}
											onKeyDown={() => onChange(o.id)}
											name={o.id}
										>
											<Text>{o.name}</Text>
										</div>
									))}
							</div>
						))}
						{onReset && (
							<div
								role="button"
								tabIndex="0"
								className="px-4 py-2 cursor-pointer transition-colors hover:bg-ca-silver"
								onClick={onReset}
								onKeyDown={onReset}
							>
								<Text className="font-bold">
									Reset filters
									<CrossIcon className="inline ml-1 h-2.5 text-ca-red-a" />
								</Text>
							</div>
						)}
					</>
				)}
			</div>
		</Transition>
	);
};

FilterFieldMenu.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number,
			name: PropTypes.string,
		})
	),
	loading: PropTypes.bool,
	visible: PropTypes.bool,
	onChange: PropTypes.func,
	onReset: PropTypes.func,
};

FilterFieldMenu.defaultProps = {
	options: [],
	loading: false,
	visible: false,
	onChange: () => {},
	onReset: () => {},
};

export default FilterFieldMenu;
