import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import useKeyPress from '../../hooks/useKeyPress';

import FilterButton from './FilterButton';
import FilterFieldMenu from './FilterFieldMenu';
import FilterValueMenu from './FilterValueMenu';
import FilterTag from './FilterTag';
import UseChannelQuery from '../../hooks/useChannelQuery';

const FullFilterMenu = ({ filters, onChange, filterQuery }) => {
	const ref = useRef();
	const [filtersVisible, setFiltersVisible] = useState(false);
	const [[selectedField, selectedType], setSelectedField] = useState([]);
	const { isLoading: isFiltersLoading, data = [] } = filterQuery;

	const handleFiltersClose = () => {
		setSelectedField([]);
		setFiltersVisible(false);
	};

	const handleFiltersOpen = () => {
		setSelectedField([]);
		setFiltersVisible(true);
	};

	useOnClickOutside(ref, handleFiltersClose);
	useKeyPress('Escape', handleFiltersClose);

	const handleFieldChange = (id, type) => {
		const filter = data.find((f) => f.id === id);
		setSelectedField([filter, type]);
	};

	const handleFilterChange = (value) => {
		onChange((f) => new Map(f.set(selectedField?.id, value)));
		handleFiltersClose();
	};

	const handleFilterRemove = (filter) => {
		onChange((f) => {
			const newMap = new Map(f);
			newMap.delete(filter);
			return newMap;
		});
		handleFiltersClose();
	};

	const handleFiltersReset = () => {
		onChange(new Map());
		handleFiltersClose();
	};

	const activeFilters = Array.from(filters.entries()).map(([id, val]) => {
		const filter = data.find((f) => f.id === id);

		let value = val;

		if (filter.type === 'Range') {
			const [min, max] = val;

			if (filter.percentage) {
				value = `${(min * 100).toFixed(filter.decimals)}% - ${(
					max * 100
				).toFixed(filter.decimals)}%`;
			} else {
				value = `${min.toFixed(filter.decimals)} - ${max.toFixed(
					filter.decimals
				)}`;
			}
		}

		return {
			id: filter.id,
			name: filter.name,
			value,
		};
	});

	// filter out currently active filters
	const availableFilters = data.filter(
		(f) => !activeFilters.map(({ id }) => id).includes(f.id)
	);

	return (
		<div className="flex justify-between items-center">
			<div className="flex items-center" ref={ref}>
				<div className="relative mr-3">
					<FilterButton
						disabled={!!activeFilters.length && !availableFilters.length}
						onClick={handleFiltersOpen}
					/>
					<FilterFieldMenu
						options={availableFilters}
						loading={isFiltersLoading}
						visible={filtersVisible}
						onChange={(fieldId) => handleFieldChange(fieldId, 'menu')}
						onReset={activeFilters.length ? handleFiltersReset : null}
					/>
					<FilterValueMenu
						key={selectedField?.id}
						id={selectedField?.id}
						field={selectedField?.name || ''}
						value={filters.get(selectedField?.id) || ''}
						options={
							selectedField?.checklist_options ||
							selectedField?.list_options ||
							selectedField?.range_options
						}
						type={selectedField?.type}
						decimals={selectedField?.decimals}
						percentage={selectedField?.percentage}
						// only show dropdown if there is an active field, and the type is 'menu'
						visible={!!selectedField && selectedType === 'menu'}
						onApply={handleFilterChange}
						onCancel={handleFiltersClose}
						className="left-full ml-60"
					/>
				</div>
				<div className="flex flex-wrap gap-3">
					{activeFilters.map((f) => (
						<div key={f.id} className="relative inline">
							<FilterTag
								onClick={() => {
									setFiltersVisible(false);
									handleFieldChange(f.id, 'tag');
								}}
								field={f.name}
								value={f.value}
							/>
							<FilterValueMenu
								key={selectedField?.id}
								id={selectedField?.id}
								field={selectedField?.name || ''}
								value={filters.get(selectedField?.id) || ''}
								options={
									selectedField?.checklist_options ||
									selectedField?.list_options ||
									selectedField?.range_options
								}
								type={selectedField?.type}
								decimals={selectedField?.decimals}
								percentage={selectedField?.percentage}
								visible={
									// only show dropdown if currently active field is this one, and the type is 'tag'
									selectedField?.id === f.id && selectedType === 'tag'
								}
								onApply={handleFilterChange}
								onRemove={() => handleFilterRemove(f.id)}
								className="left-0"
							/>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

FullFilterMenu.propTypes = {
	filters: PropTypes.instanceOf(Map),
	onChange: PropTypes.func,
	filterQuery: PropTypes.objectOf(UseChannelQuery),
};

FullFilterMenu.defaultProps = {
	filters: new Map(),
	onChange: () => {},
};

export default FullFilterMenu;
