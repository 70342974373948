import PropTypes from 'prop-types';
import useChannelQuery from '../../shared/hooks/useChannelQuery';

import { GET_OPPORTUNITIES_FILTERS } from '../../shared/api/opportunities';
import useStrategyStore from '../../shared/data/strategies';
import FullFilterMenu from '../../shared/components/Filter/FullFilterMenu';

const OpportunitiesFilter = ({ filters, onChange }) => {
	const { activeStrategy } = useStrategyStore();
	const filterQuery = useChannelQuery(
		['opportunities-filters', activeStrategy],
		() => GET_OPPORTUNITIES_FILTERS(activeStrategy),
		{
			staleTime: 5 * 60 * 1000,
		}
	);
	if (!filterQuery) return null;
	return (
		<FullFilterMenu
			filterQuery={filterQuery}
			filters={filters}
			onChange={onChange}
		/>
	);
};

OpportunitiesFilter.propTypes = {
	filters: PropTypes.instanceOf(Map),
	onChange: PropTypes.func,
};

OpportunitiesFilter.defaultProps = {
	filters: new Map(),
	onChange: () => {},
};

export default OpportunitiesFilter;
