import FullCumulioDashboard from '../../shared/components/FullCumulioDashboard/FullCumulioDashboard';
import { GET_CUMULIO_ACTUALS_YOYCOMPARISON_SSO } from '../../shared/api/reports';
import filtersToSearchParams from '../../shared/utils/filters';

const ActualsYoYComparison = ({ filters }) => {
	return (
		<FullCumulioDashboard
			queryKey={['cumulo-actuals-yoycomparison', Object.fromEntries(filters)]}
			ssoQuery={() =>
				GET_CUMULIO_ACTUALS_YOYCOMPARISON_SSO(filtersToSearchParams(filters))
			}
		/>
	);
};

export default ActualsYoYComparison;
