function filtersToSearchParams(filters) {
	return new URLSearchParams([
		...Array.from(filters.entries()).map(([id, value]) => [
			'filter',
			`"id":"${id}","value":${JSON.stringify(value)}`,
		]),
	]);
}

export default filtersToSearchParams;
